import pickup from "@/api/agency/request-pickup";

import { baseState, baseMutations } from "@/store/mixins";
import { split } from "lodash";
import moment from "moment";

export const state = {
    ...baseState,
    requestDate: moment().format("DD-MM-YYYY"),
    status: undefined,
    types: undefined,
		driver_assigned: undefined,
    agency_id: [],
    formModels: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_REQUEST_DATE(state, value) {
        state.requestDate = value;
    },
    SET_STATUS(state, value) {
        state.status = value;
    },
    SET_TYPES(state, value) {
        state.types = value;
    },
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            split(index, ",").forEach(el => {
                state.formModels[el] = data[el];
            });
        }
    },
    SET_SERVICE_AGENCY(state, value) {
        state.agency_id = value;
    },
    SET_HAS_ASSIGNED_DRIVER(state, value) {
        state.driver_assigned = value;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    request_date: context.state.requestDate,
                    status: context.state.status,
                    types: context.state.types,
                    agency_id: context.state.agency_id,
										driver_assigned: context.state.driver_assigned
                }
            },
            attributes
        );
        return pickup.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    assignDriver(context, data) {
        return pickup.assign(data);
    },
    requestPickupForShop(context, data) {
        return pickup.requestPickupForShop(data);
    },
    requestDelivery(context, data) {
        return pickup.requestDelivery(data);
    },
    cancelRequest(context, data) {
        return pickup.cancelRequest(data);
    },
    nearByDrive(context, data) {
        return pickup.nearByDrive(data);
    },
    async getFormViewData(context, { params, index }) {
        let response = await pickup.formModel({ params: params });
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: index
        });
        return response;
    },
    confirmRequest(context, data) {
        return pickup.confirmRequest(data);
    },
    changeServiceType(context, data) {
        return pickup.changeServiceType(data);
    }
};
